import React, { Component } from 'react'
import Layout from '../components/layout/layout'
import Hero from '../components/Hero'
import ContactCard from '../components/Contact/ContactCard'
import ContactForm from '../components/Common/ContactForm'

export default class ContactPage extends Component {
  render() {
    const titleBackground = require('../assets/images/alex-knight-181471-unsplash.jpg')

    return (
      <Layout location={this.props.location}>
        <div className="gdlr-core-page-builder-body">
          <Hero
            title="Contact us"
            description="Get in touch"
            heroImage={titleBackground}
            withOpacity
          />
          <div
            className="gdlr-core-pbf-wrapper "
            style={{ padding: '35px 0px 30px 0px' }}
          >
            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
              <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                <ContactCard
                  title="Phone"
                  description="Reach out for a quick chat. Alternatively please chat to us on our pop up on the bottom right"
                  icon="fa fa-phone"
                  linkTo="tel:+27840344111"
                  link="(+27) 214182812 | (+27) 840344111"
                />

                <ContactCard
                  icon="fa fa-envelope-o"
                  title="Email"
                  description="Responses take about a day, except on public holidays."
                  linkTo="mailto:info@boragrowth.com"
                  link="info@boragrowth.com"
                />
                <ContactCard
                  icon="fa fa-envelope-o"
                  title="Location"
                  description="50 Long Street
                  Cape Town, WC, South Africa, 8001"
                  linkTo="https://goo.gl/maps/QSpbSdsEkqr"
                  link="View On Google Map"
                />
              </div>
            </div>
          </div>
          <div
            className="gdlr-core-pbf-wrapper "
            style={{ padding: '95px 0px 50px 0px' }}
            data-skin="Contact Form White"
          >
            <div
              className="gdlr-core-pbf-background-wrap"
              style={{ backgroundColor: '#f3f3f3' }}
            />
            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
              <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                <div className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
                  <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                    <div
                      className="gdlr-core-pbf-column-content clearfix gdlr-core-js "
                      style={{ maxWidth: 760 }}
                    >
                      <div className="gdlr-core-pbf-element">
                        <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr">
                          <div className="gdlr-core-title-item-title-wrap ">
                            <h3
                              className="gdlr-core-title-item-title gdlr-core-skin-title "
                              style={{
                                fontSize: 39,
                                fontWeight: 600,
                                letterSpacing: 0,
                                textTransform: 'none',
                              }}
                            >
                              Leave us your info{' '}
                              <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                            </h3>
                          </div>
                          <span
                            className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                            style={{ fontSize: 19, fontStyle: 'normal' }}
                          >
                            and we will get back to you.
                          </span>
                        </div>
                      </div>
                      <div className="gdlr-core-pbf-element">
                        <div
                          className="gdlr-core-divider-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-divider-item-normal"
                          style={{ paddingBottom: 50 }}
                        >
                          <div
                            className="gdlr-core-divider-container gdlr-core-center-align"
                            style={{ maxWidth: 40 }}
                          >
                            <div
                              className="gdlr-core-divider-line gdlr-core-skin-divider"
                              style={{
                                borderColor: '#2b2b2b',
                                borderBottomWidth: 2,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="gdlr-core-pbf-element">
                        <div className="gdlr-core-contact-form-7-item gdlr-core-item-pdlr gdlr-core-item-pdb ">
                          <div
                            role="form"
                            className="wpcf7"
                            id="wpcf7-f1319-p1964-o1"
                            lang="en-US"
                            dir="ltr"
                          >
                            <div className="screen-reader-response" />
                            <ContactForm
                              centreButton
                              location={this.props.location.pathname}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="gdlr-core-pbf-wrapper "
            style={{ padding: '80px 0px 50px 0px' }}
          >
            <div
              className="gdlr-core-pbf-background-wrap"
              style={{ backgroundColor: '#ffffff' }}
            />
            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
              <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                <div className="gdlr-core-pbf-element">
                  <div className="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-center-align gdlr-core-item-pdlr">
                    <a
                      href="mailto:info@boragrowth.com"
                      target="_blank"
                      className="gdlr-core-social-network-icon"
                      title="email"
                      style={{ fontSize: 20, color: '#1e1e1e' }}
                    >
                      <i className="fa fa-envelope" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/bora-growth-partners/"
                      target="_blank"
                      className="gdlr-core-social-network-icon"
                      title="linkedin"
                      style={{
                        fontSize: 20,
                        color: '#1e1e1e',
                        marginLeft: 40,
                      }}
                    >
                      <i className="fa fa-linkedin" />
                    </a>
                    <a
                      href="https://twitter.com/BoraGrowth"
                      target="_blank"
                      className="gdlr-core-social-network-icon"
                      title="twitter"
                      style={{
                        fontSize: 20,
                        color: '#1e1e1e',
                        marginLeft: 40,
                      }}
                    >
                      <i className="fa fa-twitter" />
                    </a>
                    <a
                      href="https://facebook.com/BoraGrowth"
                      target="_blank"
                      className="gdlr-core-social-network-icon"
                      title="facebook"
                      style={{
                        fontSize: 20,
                        color: '#1e1e1e',
                        marginLeft: 40,
                      }}
                    >
                      <i className="fa fa-facebook" />
                    </a>
                    <a
                      href="https://instagram.com/BoraGrowth"
                      target="_blank"
                      className="gdlr-core-social-network-icon"
                      title="instagram"
                      style={{
                        fontSize: 20,
                        color: '#1e1e1e',
                        marginLeft: 40,
                      }}
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
