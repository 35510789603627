import React, { Component } from 'react'

export default class ContactCard extends Component {
  render() {
    return (
      <div className="gdlr-core-pbf-column gdlr-core-column-20">
        <div
          className="gdlr-core-pbf-column-content-margin gdlr-core-js "
          style={{ padding: '50px 20px 0px 20px' }}
        >
          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
            <div className="gdlr-core-pbf-element">
              <div
                className="gdlr-core-icon-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                style={{ paddingBottom: 45 }}
              >
                <i
                  className={`gdlr-core-icon-item-icon ${this.props.icon}`}
                  style={{
                    color: '#454545',
                    fontSize: 40,
                    minWidth: 40,
                    minHeight: 40,
                  }}
                />
              </div>
            </div>
            <div className="gdlr-core-pbf-element">
              <div
                className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style={{ paddingBottom: 25 }}
              >
                <div className="gdlr-core-title-item-title-wrap ">
                  <h3
                    className="gdlr-core-title-item-title gdlr-core-skin-title "
                    style={{
                      fontSize: 26,
                      fontWeight: 600,
                      letterSpacing: 0,
                      textTransform: 'none',
                    }}
                  >
                    {this.props.title}
                    <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                  </h3>
                </div>
              </div>
            </div>
            <div className="gdlr-core-pbf-element">
              <div
                className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                style={{ paddingBottom: 0 }}
              >
                <div
                  className="gdlr-core-text-box-item-content"
                  style={{ fontSize: 16 }}
                >
                  <p>{this.props.description}</p>
                </div>
              </div>
            </div>
            <div className="gdlr-core-pbf-element">
              <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div
                  className="gdlr-core-text-box-item-content"
                  style={{ fontSize: 16 }}
                >
                  <p>
                    <a target="_blank" href={this.props.linkTo}>
                      {this.props.link}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
